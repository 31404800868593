import * as types from './ActionConst';
import {checkProps,toJson,selectImportantData} from '../util/commonInput';
import {axiosPost,server} from './axiosPost';

//システム状態関係
export const login = () => ({ type:types.LOGIN});
export const login2 = () => ({ type:types.LOGIN2});
export const logOut = () => ({ type:types.LOGOUT});
export const introduce = () => ({ type:types.INTRODUCE});
export const logStatus = (status) =>({type:types.LOGSTATUS,status});
export const menu = (menuList) => ({ type:types.MENU,menuList})
//共通Action
/*
getReducerData
共通Reducer（その他のReducer）の内容を取得するためのActionです
Parameter：　key　→　取得したDataはKeyのReducerにあげます
　　　　　　　orgReducer　→　取得するデータの居場所
　　　　　　　　　　　　　　　例えば：　共通ReducerのLogin区分の内容を取得したい場合
　　　　　　　　　　　　　　　　　　　　orgReducerは'COMMON.Login'になる
Function：　selectImportantData　→　orgReducerを条件として、Stateの中にデータを抽出するFunction
            dispatch,getState　→　Redux　内部定義のFunction
*/
export const getReducerData = (key,orgReducer) =>(dispatch,getState) =>{
  const state = getState();
  const data = selectImportantData(state,orgReducer);
  return dispatch({type:types.GET_REDUCER_DATA+key,data});
}

export const back = (key,page,mode) => ({ type: types.BACK+key, page:page,mode:mode});// 戻る(引数はKEYと戻り先にしたいCurrentPage番号)
//入力画面の離脱確認//reduxのMiddlewareを利用して　Dispatchの内部転送やっています
export const checkBeforeBack = (key,page,mode) => (dispatch,getState) =>{
       //画面の項目保存
       dispatch(setDidSet(key,'inputValues',true))
       //※getStateというFunctionはReduxのStoreの値を取れます
      if(!getState()[key].inputValues.isChanged || window.confirm('入力した項目があるので、本当に戻りますか')){
        dispatch(pageMove({currentPage:page,mode:page === 1?'0':mode}));
        dispatch(inputErr(""));//ErrMsgClear
        return dispatch(back(key,page,mode))
      }else{
        return;
      }
}
//コードで名称を自動引っ張るロジック
//Java側は全部Keyという名で値を受けるように固定
//FrontEnd側はRes.data.labelという形で名称を取る(議論：名称を直で返すことはよいか
export const getName = (key,props,url,prefix,lang) => (dispatch) =>{
  let para = prefix
  let path = server+url + '?key='+props[para].value;
  if(lang && lang !== '')path += '&lang='+lang;
  axiosPost.get(path)
                   .then((res) => {
                       if(res.data != null){
                         dispatch(formEdit(key,prefix.slice(0,-4)+'Name',res.data.label))
                         dispatch(setDidSet(key,'inputValues',true))
                        }
                   }).catch(
                       err => {
                         console.log(err)
                       }
                   );
}

export const setDidSet = (key,name,didSet) => ({type:types.SETDIDSET + key,name,didSet});

export const tableState = (key,newTableState) => ({ type: types.TABLESTATE+key, newTableState });//検索リスト現在頁の記憶

// 必須チェック(検索画面)
export const doCheck = (key,props) => (dispatch) =>{
  // console.log(key,props)
  if(!checkProps(props)){
    //dispatch(check(key));
    dispatch(searchErr("必須項目を入力してください"));
    dispatch(show(key,false));
  }else{
    dispatch(searchErr(""));//ErrMsgClear
    dispatch(search(key,props.selectValues));
    dispatch(loadSearchList(key,'/'+key+'/search',toJson(props)));
  }
};
// 必須チェック(登録画面)
export const doConfirm = (key,props,url,json) => (dispatch) =>{
  dispatch(setDidSet(key,'inputValues',false))
  if(!checkProps(props)){
    dispatch(inputErr("必須項目を入力してください"));
    dispatch(setDidSet(key,'inputValues',true));
  }else{
    dispatch(inputErr(""));//ErrMsgClear
    dispatch(confirm(key,url,json));
  }
};
// 登録処理
export const doUpdate = (key,props,url,json,page) => (dispatch) =>{
  dispatch(setDidSet(key,'inputValues',false))
  //削除モード以外の時，必須Checkします
  if(props.mode !== '3' && !checkProps(props)){
    dispatch(inputErr("必須項目を入力してください"));
    dispatch(setDidSet(key,'inputValues',true));
  }else{
    const forwardPage = (page === null || typeof page ==='undefined')?1:page;
    dispatch(inputErr(""));//ErrMsgClear
    dispatch(update(key,url,json,forwardPage));
  }
};
// 必須チェック(登録画面)
export const checkBeforeUpdate = (key,props,url,json) => (dispatch) =>{
  dispatch(inputErr(""));//ErrMsgClear
  if(!checkProps(props)){
    dispatch(inputErr("必須項目を入力してください"));
  }else{
    dispatch(update(key,url,json));
  }
};

//エラー関係
export const error = (errMsg) => ({type:types.ERR,errMsg});// メッセージ表示エラー
export const searchErr = (errMsg) => ({type:types.SEARCH_ERR,errMsg});// メッセージ表示エラー
export const inputErr = (errMsg) => ({type:types.INPUT_ERR,errMsg});// メッセージ表示エラー
export const fatalError = (errMsg) => ({type:types.FATALERR,errMsg});// エラー画面に遷移
export const sessionError = () => ({type:types.SESSIONERR});// セッションエラー画面に遷移

//SearchList関係　Start
export const show = (key,value) => ({ type: types.SHOW_SEARCHLIST+key,showFlg:value});// searchListを出す
export const searchEdit = (key,name,value) => ({ type: types.SEARCHEDIT+key,name,value });// 検索画面の入力情報の編集(値変更時にstateに更新)
export const loadSearchList = (key,url,json) => (dispatch) => {   // 検索し、検索結果を取得する
  if (url=='') {
    return dispatch(setSearchList(key,json));
  }
  axiosPost.post(server+url,json)
  .then((res) => {
    if(res.data != null){
      if (res.data.formErrorMsg!=null) {
        dispatch(searchErr(res.data.formErrorMsg))
      }else{
        dispatch(setSearchList(key,res.data));
      }
    }
  }).catch(err => {
    // セッションエラー
    if(err.response){
      if (err.response.status===400) {
        return  dispatch(sessionError(err))
      } else {
        return  dispatch(fatalError(err.response.data.exception+'\n'+err.response.data.message))
      }
    }else{
      //FataErrorのNetWorkNotExist
      return dispatch(fatalError('unknown error'))
    }
  })
}
export const openSearchArea = (key) => ({ type: types.OPENSEARCHAREA+key });// 
export const clearSearchList = (key) => ({type:types.CLEARSEARCHLIST+key});
//SearchList関係　End

//input画面関係　　Start
export const checkOn = (key) => ({ type:types.CHECKON+key });// 
export const checkOff = (key) => ({ type:types.CHECKOFF+key });// 
export const formEdit = (key,name,value) => ({ type: types.FORMEDIT+key,name,value });// 入力画面の入力情報の編集(値変更時にstateに更新)
export const detail = (key,payload) => (dispatch) =>{
  switch(payload.mode){
    case '2' : dispatch(edit(key,payload));break; // 編集
    case '3' : dispatch(del(key,payload));break;  // 削除
    case '4' : dispatch(copy(key,payload));break; // 複写
    default:
      return
  }
}
export const add = (key) =>({ type: types.ADD+key }); // 新規登録ボタン押した時

export const confirm = (key,url,json) => (dispatch) =>{ // 確認ボタン押した時
  // console.log('confirm_post', url, json)
  axiosPost.post(server+url,json)
  .then((res) => {
    // console.log('confirm_response', res)
    if(res.data != null){
      if (res.data.formErrorMsg!=null) {
        dispatch(inputErr("ERROR:"+res.data.formErrorMsg))
      }else{
        dispatch(checkOn(key));
        dispatch(setInput(key, res.data));
      }
      dispatch(setDidSet(key,'inputValues',true))
    }
  }).catch(err => {
    // セッションエラー
    if(err.response){
      if (err.response.status===400) {
        return  dispatch(sessionError(err))
      } else {
        return dispatch(fatalError(err.response.data.exception+'\n'+err.response.data.message))
      }
    }else{
      //FataErrorのNetWorkNotExist
      return dispatch(fatalError('unknown error'))
    }
    })
}

export const update = (key,url,json,returnPage) => (dispatch) =>{ // 登録ボタン押した時
  // console.log(json)
  axiosPost.post(server+url,json)
  .then((res) => {
    if(res.data != null){
      if (res.data.formErrorMsg!=null) {
        if(key === 'ESA01'|| key === 'EDA01' ){
          //Search画面から直で更新する機能
          dispatch(searchErr("ERROR:"+res.data.formErrorMsg))
        }else{
          dispatch(inputErr("ERROR:"+res.data.formErrorMsg))
        }
      }else{
        dispatch(back(key,returnPage,returnPage === 1?'0':json.mode));
        dispatch(pageMove({currentPage:returnPage,mode:returnPage === 1?'0':json.mode}));//共通アリアのCurrentPage,mode更新
      }
    }
  }).catch(err => {
    // セッションエラー
    if(err.response){
      if (err.response.status===400) {
        return  dispatch(sessionError(err))
      } else {
        return dispatch(fatalError(err.response.data.exception+'\n'+err.response.data.message))
      }
    }else{
      //FataErrorのNetWorkNotExist
      return dispatch(fatalError('unknown error'))
    }
    })
}


export const updateFromSearch = (key,url,json,returnPage) => (dispatch) =>{ // 登録ボタン押した時
  axiosPost.post(server+url,json)
  .then((res) => {
    // console.log(res.data);
    if(res.data != null){
      if (res.data.formErrorMsg!=null) {
          //Search画面から直で更新する機能
          dispatch(searchErr(res.data.formErrorMsg))
      }else{
        // dispatch(pageMove({currentPage:returnPage,mode:returnPage === 1?'0':json.mode}));//共通アリアのCurrentPage,mode更新
        dispatch(clearSearchList(key));
        dispatch(show(key,false));
        dispatch(setDidSet(key,'searchValues',true));
      }
    }
  }).catch(err => {
    // セッションエラー
    if(err.response){
      if (err.response.status===400) {
        return  dispatch(sessionError(err))
      } else {
        return dispatch(fatalError(err.response.data.exception+'\n'+err.response.data.message))
      }
    }else{
      //FataErrorのNetWorkNotExist
      return dispatch(fatalError('unknown error'))
    }
    })
}

export const loadSearch = (key,url,json) => (dispatch) => {// 
  axiosPost.post(server+url,json)
  .then((res) => {
    // console.log("loadSearch:"+res.data);
    if(res.data != null){
      if (res.data.formErrorMsg!=null) {
        dispatch(searchErr(res.data.formErrorMsg))
      }else{
        dispatch(clearSearchList(key));
        dispatch(show(key,false));
        dispatch(setSearch(key,res.data));
        // console.log(res.data)
      }
      dispatch(setDidSet(key,'searchValues',true));
    }
  }).catch(err => {
    // セッションエラー
    if(err.response){
      if (err.response.status==400) {
        return  dispatch(sessionError(err))
      } else {
        return dispatch(fatalError(err.response.data.exception+'\n'+err.response.data.message))
      }
    }else{
      //FataErrorのNetWorkNotExist
     return dispatch(fatalError('unknown error'))
    }
  })
}

export const loadInput = (key,url,json) => (dispatch) => {// 編集・削除・複写時にデータを取得する
  axiosPost.post(server+url,json)
  .then((res) => {
    if(res.data != null){
      if (res.data.formErrorMsg!=null) {
        dispatch(inputErr(res.data.formErrorMsg))
      }else{
        dispatch(setInput(key,res.data,json.mode));
        // console.log(res.data)
      }
      dispatch(setDidSet(key,'inputValues',true));
    }
  }).catch(err => {
    // セッションエラー
    if(err.response){
      if (err.response.status==400) {
        return  dispatch(sessionError(err))
      } else {
        return dispatch(fatalError(err.response.data.exception+'\n'+err.response.data.message))
      }
    }else{
      //FataErrorのNetWorkNotExist
     return dispatch(fatalError('unknown error'))
    }
  })
}
//input画面関係　　End

// 出力関係
export const excel = (key,url,json,fileName) => (dispatch) => {
  var mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  axiosPost.post(server+url,json,{responseType: 'arraybuffer'})
  .then((res) => {
    // ダウンロードしたいコンテンツ、MIMEType、ファイル名
    var name     = fileName+'.xlsx';

    // BOMは文字化け対策
    var bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
    var blob = new Blob([res.data], {type: mimeType});

    var a = document.createElement('a');
    a.download = name;
    a.target   = '_blank';
    if (window.navigator.msSaveBlob) {
      // for IE
      window.navigator.msSaveBlob(blob, name)
    }
    else if (window.URL && window.URL.createObjectURL) {
      // for Firefox
      a.href = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    else if (window.webkitURL && window.webkitURL.createObject) {
      // for Chrome
      a.href = window.webkitURL.createObjectURL(blob);
      a.click();
    }
    else {
      // for Safari
      window.open('data:' + mimeType + ';base64,' + window.Base64.encode(res.data), '_blank');
    }
  })
  .catch(err => {
    alert('エラーです'+err);
    console.log('JSON読み込みエラー')
      return
  })
 };
 export const pdf = (key,url,json,fileName) => (dispatch) => {
  axiosPost.post(server+url,json,{responseType: 'arraybuffer'})
  .then((res) => {
      // ダウンロードしたいコンテンツ、MIMEType、ファイル名
      var mimeType = 'application/pdf';
      var name     = fileName+'.pdf';

      // BOMは文字化け対策
      var bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
      var blob = new Blob([res.data], {type : mimeType});

      var a = document.createElement('a');
      a.download = name;
      a.target   = '_blank';

      if (window.navigator.msSaveBlob) {
        // for IE
        window.navigator.msSaveBlob(blob, name)
      }
      else if (window.URL && window.URL.createObjectURL) {
        // for Firefox
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      else if (window.webkitURL && window.webkitURL.createObject) {
        // for Chrome
        a.href = window.webkitURL.createObjectURL(blob);
        a.click();
      }
      else {
        // for Safari
        window.open('data:' + mimeType + ';base64,' + window.Base64.encode(res.data), '_blank');
      }
  }) 
  .catch(err => {
    alert(err);
    console.log('JSON読み込みエラー')
      return
  })
 };
 export const csv = (key,url,json,fileName) => (dispatch) => {
  axiosPost.post(server+url,json,{responseType: 'blob'})
  .then((res) => {
      if (window.navigator.msSaveOrOpenBlob) {
        // for IE,Edge
        window.navigator.msSaveOrOpenBlob(res.data, fileName+'.csv');
      } else {
        // for chrome, firefox
        const url = URL.createObjectURL(new Blob([res.data], {type: 'text/csv'}));
        const linkEl = document.createElement('a');
        linkEl.href = url;
        linkEl.setAttribute('download', fileName+'.csv');
        document.body.appendChild(linkEl);
        linkEl.click();
    
        URL.revokeObjectURL(url);
        linkEl.parentNode.removeChild(linkEl);
      }
  }) 
  .catch(err => {
    alert(err);
    console.log('JSON読み込みエラー')
      return
  })
 };
 // 出力関係　END

//action内部用DispatchはExportしません
const check = (key) => ({type:types.CHECK+key});// チェック
export const search = (key) => ({ type: types.SEARCH+key });// 検索
const setSearch = (key,json) => ({ type: types.LOADSEARCH+key,json});// 入力
const setSearchList = (key,searchList) => ({ type: types.LOADSEARCHLIST+key,searchList });// 一覧表示
const setInput = (key,json,mode,isConfirm) => ({ type: types.LOADINPUT+key,json,mode,isConfirm});// 入力
//payload=必要条件のObject
const del = (key,payload) => ({ type: types.DELETE+key , payload}); // 削除
const copy = (key,payload) => ({ type: types.COPY+key, payload });  // 複写
const edit = (key,payload) => ({ type: types.EDIT+key, payload});   // 修正
export const pageMove = (payload) => ({type:types.PAGEMOVE,payload});  //currentPageの移動
