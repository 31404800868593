// 入力用テーブルに使用しています。変更する際は気を付けてください。

import React, {Fragment} from 'react'
import calendar_icon from '../image/calendar_icon.png';

export default class TableCalendar extends React.Component{
  constructor(props){
    super(props);
    this.state={
      date          : new Date().getDate(),         // 今日の日付 
      month         : new Date().getMonth()+1,      // 表示中の月
      year          : new Date().getFullYear(),     // 表示中の年
      breakPoint    : 6,                            // 行の最後にくる曜日。（折り返し地点）
      selectedDate  : null,                         // 選択されている日付け
      cursorDate    : null,                         // キーダウンでカーソル中の日付
      showFlag      : false,                        // カレンダーの表示／非表示
      view          : this.props.type  || null,     // 表示の切り替え（日付け／月）               
      value         : this.props.value || "",       // 最終的に送り出す値　YYYY/MM/DD 形式
      hover     : false,
      focus     : false,
    };
    this.inputRef          = React.createRef();     // viewでユーザーが処理するための<input>
    this.elementClickFlag  = false;   // クリックされた場所が、要素上かどうか検知するために定義

    this.year  = new Date().getFullYear() ;                       // 年を抽出
    this.month = ("0" + ( new Date().getMonth()+1) ).slice(-2);   // 月をMM方式で抽出（ ("0" + " ").slice(-2) とすることで頭に０をつけることができる　）
    this.date  = ("0" + new Date().getDate() ).slice(-2);     // 現在選択中の日をDD方式で抽出（ ("0"+" ").slice(-2) とすることで頭に０をつけることができる　）
    
    this.today  = this.year +"/"+ this.month +"/"+ this.date      // 今日の日付を定義
    this.todayMonth = this.year +"/"+ this.month
  }
  // _______________________________________________________________________________________________________________________________

  componentDidMount(){
    const{ autoFocus,value } = this.props
    if( autoFocus ){ this.inputRef.current.focus() }　// オートフォーカス機能
    if( value && new Date( value ) != "Invalid Date" ){ // もしデータが日付として正しいなら
      this.setState({ value : value.replace( /-/g,"/")})// "-"(ハイフン)を"/"(スラッシュ)に置換
    }
  }
  // _______________________________________________________________________________________________________________________________

  shouldComponentUpdate( nextProps ){
    if( this.props.value !== nextProps.value ){
      // console.log( this.props.value,'→' ,nextProps.value )
      this.setState({ value : nextProps.value})
    }
    return true;
  }
  // ________________________________________________________________________________________________________________


  componentDidUpdate(){
    if( !this.state.showFlag ){                               // カレンダーが閉じている時
      window.removeEventListener('click',this.pageClick,)         // windowイベントpageClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }     
  }
  // _______________________________________________________________________________________________________________________________

  // 表示している月を変える関数（directionは方向　値は　"pre" または "next" ）

  moveMonth(direction,e){
    e && e.preventDefault();
    const { year, month } = this.state

    switch(direction){
      case "pre" :    // 前
        if (month > 1) {                               // 表示月が１より大きい場合
          this.setState({month:month - 1})             // 表示月を－１します
        }
        else{                                           // それ以外なら（1月なら）
          this.setState({year:year - 1})               // 表示年を－１して
          this.setState({month:12})                    // 表示月を１２月にします
        }
      break;

      case "next" :   // 次
        if (month < 12) {                              // 表示月が１２より小さい場合
          this.setState({month:month + 1})             // 表示月を＋１します
        }
        else{                                          // それ以外なら(12月なら)
          this.setState({year:year + 1})               // 表示年を＋１して
          this.setState({month:1})                    // 表示月を１月にします                      
        }
      break;

      default: 
    }
  }
  // _____________________________________________________________________________________________________________

  // テキストボックスにフォーカスが当たった時

  openCalendar=()=>{
    const { showFlag, }= this.state
    this.setState({ focus : true })    // 背景色を変更
    window.addEventListener('click',this.pageClick,)   // windowイベントpageClick関数を作成します。

    if ( this.changeView ){ return } // カレンダーが年表示から月表示に切り替わるときは処理させない
    
    this.setState({showFlag:!showFlag})        // カレンダーを開いていたら閉じる。閉じていたら開く。
    this.setState({cursorDate: null})          // cursorDateをnullにすることで選択した日付に自動フォーカスさせています

    //  表示する位置を切り替える処理
    if( !this.state.showFlag ){  // hiddenboxが隠れていたら（つまり、開くとき）        
      let positionY = this.inputRef.current.getBoundingClientRect().bottom  // hiddenboxの下辺の位置を取得
      let positionX = this.inputRef.current.getBoundingClientRect().left    // hiddenboxの左辺の位置を取得
      if( positionY + 200 > window.innerHeight ){   // hiddenboxの下辺の位置 + 200(カレンダーのサイズ)が windowの縦サイズより大きいなら
        this.setState({ showTop : true })       // boxを上部に表示する（スタイルと連携）
      }else{
        this.setState({ showTop : false })      // boxを下部に表示する（スタイルと連携）
      }
      
      if( positionX + 250 > window.innerWidth ){   // hiddenboxの下辺の位置 + 200(カレンダーのサイズ)が windowの縦サイズより大きいなら
        this.setState({ showRight : true })       // boxを上部に表示する（スタイルと連携）
      }else{
        this.setState({ showRight : false })      // boxを下部に表示する（スタイルと連携）
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // カレンダー上またはテキストボックスでマウスダウンした時 

  handleOnMouseDown=()=>{ 
    this.elementClickFlag = true;                 // カレンダー上またはテキストボックスでマウスダウンされたことを維持
  }
  // ________________________________________________________________________________________________________________

  // 画面をクリックした時 

  pageClick=()=>{               
    if( this.elementClickFlag ){                  // もしカレンダー上、またはテキストボックスでマウスダウンされていたら
      this.elementClickFlag = false;              //　elementClickFlag を初期値に戻して
      return;                                      // クリック処理を中断
    }
    this.setState({showFlag:false})            // カレンダーを閉じる
  }
  // ________________________________________________________________________________________________________________

  // 日付けカーソルを動かす処理 onKeydown

  moveDate=(e)=>{
    const {　// stateの省略宣言
      year,
      month,
      cursorDate,
      value,
      showFlag,
    } = this.state

    if( !showFlag ){ 　// カレンダーが閉じていたら
      if( e.keyCode === 13 || e.keyCode === 32 ){　// Spaceキー、またはEnterキーが押された時
        this.setState({ showFlag: true })  // カレンダーを開く
        this.elementClickFlag = true;         // カレンダー上またはテキストボックスでマウスダウンされたことを維持
      }
      return; // カレンダーが閉じているときにreturnさせて、処理をさせないためのif文です
    }
    // ________________________________________           
                  
    let   nowDate  = null;                                           // 現在カーソルされている日付け
    let   nextDate = null;                                           // ビューに反映させるために、キーダウン後の日付けを保持する
    const lastDate = new Date(year, month, 0).getDate();             // 月の最終日を取得（月ページング処理のため）
    
    if(cursorDate){  nowDate = new Date(cursorDate).getDate(); }     // 既にカーソルの当たっている日付けがあれば、その日を取得 
    else if( value ){ nowDate = new Date(value).getDate(); }    // 日付けが選択されていれば、その日を取得
    else if(this.today){ nowDate = new Date(this.today).getDate(); }    // 今日の日付の年、月抜きで取得

    switch(e.keyCode){

      case 37 :  // 左
        if( nowDate <= 1 ) { this.moveMonth("pre") }　
        nowDate -= 1;
      break;

      case 38 :　// 上
        if( nowDate - 7 < 1 ) { this.moveMonth("pre") }
        nowDate -= 7;
      break;

      case 39 :　// 右
        if( nowDate >= lastDate ) { this.moveMonth("next") }
        nowDate += 1;
      break;

      case 40 :　// 下
        if( nowDate + 7 > lastDate ) { this.moveMonth("next") }
        nowDate += 7;
      break;

      case 13 : // Enterキー
      case 32 : // スペースキー
        if( !cursorDate ){ return }     // カーソルされている日付がなければreturn
        this.setState({showFlag: false });                                  // カレンダーを閉じる
        const YYYY = year ;                                                   // 年を抽出
        const MM   = ("0" + month ).slice(-2);                     // 月をMM方式で抽出（ ("0" + " ").slice(-2) とすることで頭に０をつけることができる　）
        const DD   = ("0" + nowDate ).slice(-2);                              // 現在選択中の日をDD方式で抽出（ ("0"+" ").slice(-2) とすることで頭に０をつけることができる　）
        this.setState({ value: YYYY + "/" + MM + "/" + DD })                  // カーソルされている日付けがあれば、それを選択する。
        this.props.onChange && this.props.onChange(YYYY + "/" + MM + "/" + DD )　// 親に送る
      break;

      default :
        this.setState({showFlag:false});                                  // カレンダーを閉じる（テキストに普通に入力処理をする場合）
      break;
    }
    nextDate = new Date( year, month - 1, nowDate);                           // 次に来る日付けをData型で取得して。
    this.setState({ cursorDate: String( nextDate ) });                        // cursorDateにセットすることでビューに反映させる
  }
  // ________________________________________________________________________________________________________________

  // 曜日ボタンを押した時

  sortDate =(e)=>{
    if(e.target.value <= 0){                                                  // もし曜日ナンバーが０以下（日曜日）だったら
      this.setState({ breakPoint : 6 })                                       // 曜日ナンバー６（土曜日）をブレイクポイント（週末）にセット
    }else{                                                                    // それ以外なら
      this.setState({ breakPoint : Number(e.target.value) - 1})               // 順当に前の日付をブレイクポイントにセット
    }
  }
  // ________________________________________________________________________________________________________________

  // 日付け<button>をクリックしたとき

  onClickDate =( date )=>{    
    this.setState({ value : date});              // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）    
    this.inputRef.current.focus();                              // テキストボックスにフォーカスを当てる
    this.props.onChange && this.props.onChange(date ); // redux対応
  }
  // ________________________________________________________________________________________________________________
  
  // テキストボックスに値を入力した時
  
  handleOnChage=(e)=>{
    this.setState({ value: e.target.value });       // テキスト入力処理
    this.props.onChange && this.props.onChange(e.target.value);　// 親に送る
  }
  // ________________________________________________________________________________________________________________

  // タイトル<button>をクリックしたとき

  titleClick =()=>{
    this.setState({ view : "YYYY/MM" });  
  }
  // _________________________________________________________________________________________________________________

  // 月<button>を押した時（monthview時）

  monthOnClick =( M )=>{
    // console.log( M )
    const { type, lang } = this.props
    const { year } = this.state
    const MM   = ("0" + M ).slice(-2);     //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。

    // console.log( )
    if( type === "YYYY/MM" ){
      
      if( lang === "en" ){                                  // 英語表記だったら
        // this.setState({ value: e.target.name +""+ YYYY });  // jan.2019 表記でvalueにセット
      }else{                                                // それ以外
        this.setState({ value: year +"/"+ MM });            // 2019/01  表記でvalueにセット
      }
      this.setState({ selectedDate : new Date( year, M - 1)})
      this.props.onChange && this.props.onChange(year +"/"+ MM);　// redux対応
      this.setState({ showFlag :false });               // カレンダーを閉じる

    }else{                                                  // （親から）type が指定されていなかったら
      this.setState({ month: Number( M ) });   
      this.setState({ view : "YYYY/MM/DD" })
      this.changeView = true;
    }


    this.inputRef.current.focus();                              // テキストボックスにフォーカスを当てる
    
  }
  // _________________________________________________________________________________________________________________

  // テキストボックスからフォーカスが外れた時

  handleOnBlur =(e)=>{
    const { type } =  this.props
    const inputValue  = e.target.value.replace(/[^\d|/]/g,'');  // "0~9"と"/"以外を取り除きます。
    // console.log( inputValue )
    const lastDate    = new Date(this.year , this.month, 0).getDate();          // 今月の最終日
    let replaceValue  = "";
    let DD            = null;
    let MM            = null;
    let YYYY          = null;
    let inputLastDate = null; // 入力された月の最終日
    let checkMM     = null;   


    if( type === "YYYY/MM" ){     // 年月カレンダーの場合
      switch( inputValue.length ){                     //  入力した値の桁数に対して分岐しています
      
        case 1: case 2: // 1桁 または ２桁 の場合
        
          if( inputValue >= 1 && inputValue <= 12 ){    // ０ではなく、今月の最終日より値が下だったら
            MM =  ("0" + inputValue ).slice(-2);
            replaceValue = this.year + "/" + MM
          }  
        break;
        
        case 6 :{
          MM    =  inputValue[4] + inputValue[5];
          YYYY  =  inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // ５桁目～８桁目
          if( MM >= 1 && MM <= 12 ){
            // console.log( MM,YYYY )
            replaceValue = YYYY + "/" + MM
          }
          break
        }
        
        case 7 :{
          if( inputValue[4] === "/" ){
            replaceValue = inputValue
          }
          break;
        } 
        default: break;
      }
  
    }
    // ____________________________________________________________________________________
    
    else{
      switch( inputValue.length ){                                        //  入力した値の桁数に対して分岐しています

        case 1: case 2: // 1桁 または ２桁 の場合

          if( inputValue !== "0" && inputValue <= lastDate ){         // ０ではなく、今月の最終日より値が下だったら
            DD =  ("0" + inputValue ).slice(-2);
            replaceValue = this.year + "/" + this.month + "/" + DD
          }
          else if( inputValue[1] !== "0" && inputValue > lastDate ){   // １桁目が０ではなく、今月の最終日より値が上だったら
            DD =  ("0" + inputValue[1] ).slice(-2);
            MM =  ("0" + inputValue[0] ).slice(-2);
            replaceValue = this.year + "/" + MM + "/" + DD
          }

        break;
        
        case 3: 
          if( inputValue[1] === "/" ){                      // ２桁目に”／”が入っていたら
            DD = ("0" + inputValue[2] ).slice(-2);          // １桁目に０を追加して日付とします
          }else {                                           // それ以外なら  
            DD = inputValue[1] + inputValue[2];             // １桁目と２桁目を結合して日付とします
          }

          MM = ("0" + inputValue[0] ).slice(-2);            // ３桁目に０を追加して月とします
          inputLastDate = new Date(this.year, MM, 0).getDate();    // 入力した月の最終日

          if( inputValue[0] !== "0" && DD > 0 && DD <= inputLastDate){  // MM（月）が０ではなく、DD（日付）が０より上、かつ入力した月の最終日より小さかったら
            replaceValue = this.year + "/" + MM + "/" + DD             // valueをYYYY/MM/DDに置き換えます
          }
        break;
        
        case 4: case 5:
          if( inputValue[2] === "/" ){
            DD = inputValue[3] + inputValue[4];
            MM = inputValue[0] + inputValue[1];
          }else{
            DD = inputValue[2] + inputValue[3];
            MM = inputValue[0] + inputValue[1];
          }
          inputLastDate = new Date(this.year, MM, 0).getDate();          // 入力した月の最終日
          if( MM !== "00" && MM <= 12 && DD > 0 && DD <= inputLastDate){ 
            replaceValue = this.year + "/" + MM + "/" + DD
          }
        break;

        case 8:
          DD   = inputValue[6] + inputValue[7]                                    // １桁目と２桁目
          MM   = inputValue[4] + inputValue[5]                                    // ３桁目と４桁目
          YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // ５桁目～８桁目
          checkMM = new Date( YYYY, MM - 1, DD ).getMonth() + 1               // 日付が正当かどうか確認するための宣言（日付が32だったりすると次の月に移動するので不正当）
          checkMM = ("0" + checkMM ).slice(-2);
          if( MM === checkMM ){  
            replaceValue = YYYY + "/" + MM + "/" + DD 
          }
        break

        case 10:
          if(inputValue[4] === "/" && inputValue[7] === "/"){
            DD   = inputValue[8] + inputValue[9]                                    // 
            MM   = inputValue[5] + inputValue[6]                                    // 
            YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // 
            checkMM = new Date( YYYY, MM - 1, DD ).getMonth() + 1               // 日付が正当かどうか確認するための宣言（日付が32だったりすると次の月に移動するので不正当）
            checkMM = ("0" + checkMM ).slice(-2);
            if( MM === checkMM ){
              replaceValue = inputValue
            }
          }
        break
        default: break;
      }
      
    }

    if( MM === "00" || DD === "00" ){
      replaceValue = ""
    }

    this.setState({ value : replaceValue })
    this.props.onChange && this.props.onChange(replaceValue ); // redux対応
    this.setState({ focus : false })
  }
  // _________________________________________________________________________________________________________________

  render(){
    const { 
      size,
      disabled,
      must,
      lang,
      label,
      style,
    } = this.props

    const {
      value,
      showTop,
      showRight,
      year,
      breakPoint,     // 週の最後に来る曜日を指定する変数【０～６ : 日～土】
      month,
      cursorDate,
      hover,
      focus,
    } = this.state

    // _________________________________________________________________________________________________________________

    

    // スタイル記述欄

    const wrap = {          // <div>　一番大元の Wrap要素
      position:"relative",
      display:"inline-flex",
      flexDirection:"column",
    }
    
    const text_wrap = {            // <div>：テキストのwrap
      display:"inline-flex",
      flexDirection:"column",
      position:"relative",
      boxSizing:"border-box",
    }

    const text = {                            // <input>：テキストボックス
      background:`url(${calendar_icon})  no-repeat`,
      backgroundPosition:"center right 4px",
      backgroundSize:18,
      backgroundColor : "transparent",
      boxShadow: 
        hover ? "0px 0px 3px 0px #00f" :
        null,
      pointerEvents: 
        disabled  ? "none" : 
        null,
      width: "100%",
      padding:"0px 24px 0px 5px",
      height: "100%",
      border : "none",
      boxSizing:"border-box",
      fontSize:16,
      transition:"0.2s",
    }

    const calendar_wrap = {                   // <div>：カレンダーの親
      display: "inline-flex",
      flexDirection: "column",
      position:"absolute",
      border: "1px solid #aaa",
      zIndex:5,
      borderRadius:5,
      backgroundColor:"#fff",
      boxShadow:"2px 2px 2px #ccc",
      top:    showTop   ?  null  : "100%",
      bottom: showTop   ?  "100%": null,
      left:   showRight ?  null  : 0,
      right:  showRight ?  0     : null,
    }

    const calendar_header = {                  // <div>：カレンダーのヘッダー
      display:"flex",
      justifyContent:"space-between", 
      alignItems:"center",
      borderRadius:"inherit",
    }

    const changeMonth = {                       // <button>：月を切り替えるボタン
      border:"none",
      backgroundColor:"transparent",
      padding:8,
      fontSize:"20px",
      cursor:"pointer",
    }

    const calendar_title = {                // <button>：カレンダーのタイトル（年月）
      padding:8,
      backgroundColor: "transparent",
      border :"none",
      fontSize:"16px",
      whiteSpace: "nowrap",
      cursor:"pointer",
    }

    const week_low = {                      // <div>：カレンダーの曜日が並ぶ列
      display:"flex", 
      alignItems:"center",
    }

    const date_wrap = {
      height:"100%",
    }

    // ____________________________________________________________________________________________________________

    // 使用変数の宣言

    const dateArray       = [];                             // 日付を入れていくための配列
    // _____________________________________________________________________________________________________________
    
    // マルチ言語化（曜日、年月タイトル）

    let daysLang    = [];                         // 各言語ごとに曜日を入れる配列
    let title       = null;                           // 年月タイトルを表示するための変数
    let monthsLang  = [];

    switch(lang){

      case "en"  :
        monthsLang = ["Jan.","Feb.","Mar.","Apr.","May","June","July","Aug.","Sept.","Oct.","Nov.","Dec."]
        daysLang   = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        title      = monthsLang[month - 1] + year;                // 例：Aug.2019
      break;

      case "ja" :
      default   :
        monthsLang  = ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"];
        daysLang    = ["日","月","火","水","木","金","土"];  
        title       = year + "年" + monthsLang[month - 1] ;    // 例：2019年8月
      break;
                    
    }
    // ________________________________________________________________________________________________________________

    // 曜日を作成

    let dayArray      = [];             // 曜日と曜日No【０～６ : 日～土】と合わせるための配列
    let dayNameCount  = null;           // 曜日と曜日No【０～６ : 日～土】と合わせて配列に入れていくためのカウント変数

    if ( breakPoint === 6 ){
      dayNameCount  = 0;
    }else{
      dayNameCount  = breakPoint + 1;
    }

    for(let i = 0; i <= 6; i++){
      dayArray.push(
        <DateBtn
          dayOfweek
          key     = { dayNameCount  }
          value   = { dayNameCount  }
          onClick = { this.sortDate }
          label   = { daysLang[dayNameCount] }
        />
      )

      if( dayNameCount === 6 ){
        dayNameCount = 0;
      }else{
        dayNameCount ++ ;
      }
    }
    // ________________________________________________________________________________________________________________

    // 　先月の残りの日付けを作成

    const firstDayNo  = new Date(year, month - 1, 1).getDay();         // 当月の最初の曜日番号　※getDay()で取得できるのは、0~6の番号のため
    // console.log( new Date(year, month - 1, 0) )
    let   startDayCount = null;                                       // カレンダーの先頭に来る前月の日付けを作成するために使用するカウント変数

    if (breakPoint === 6){ startDayCount = 0 }                      // breakpointの次の曜日から週が始まるので、＋１をしています。
    else    { startDayCount = breakPoint + 1 }                      // ただしbreakpointが６（土曜日）の場合、７がないので０（日曜日）に戻します。
        
    for(let i = 0 ; i < 6 ; i++){
      
      if(startDayCount === firstDayNo) { break }                    // もしカウントが当月の1日の曜日に達したら日付作成完了です。
      else if (startDayCount === 6)    { startDayCount = 0 }        // また、もしカウントが6の場合、次は0になります。
      else                             { startDayCount ++  }        // 当月の1日に達するまでカウントしていきます

      let YYYY = null
      let MM   = null
      if( month === 1 ){  // 1月の場合 
        YYYY  = year - 1
        MM    = 12        
      }else{
        YYYY  = year
        MM  = ("0" + ( month - 1 ) ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      }
      const DD = new Date(year , month - 1, -i).getDate()                 // forループに基づいて作成される前月の日付け
      
      const madeDate  = YYYY + "/" + MM + "/" + DD

      dateArray.unshift(                                             // unshift()で<button>日付けを配列の手前に順に追加していきます。
        <DateBtn  
          notThisMonth
          key          = { madeDate }
          selected     = { madeDate === value ? true : false }
          focused      = { new Date( madeDate ) == cursorDate ? true : false }
          today        = { madeDate === this.today ? true : false }
          onClick      = { ()=>this.onClickDate( madeDate )}
          label        = { DD }
        />
      )

    }
    // ________________________________________________________________________________________________________________

    //  当月の日付けを作成   
    
    const lastDate = new Date(year , month, 0).getDate();          // 当月の最終日

    for(let i = 1; i <= lastDate; i++){
      const MM          = ("0" + month ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      const DD          = ("0" + i ).slice(-2)
      const madeDate    = year + "/" + MM + "/" + DD
      let dayNo         = new Date( madeDate ).getDay();                           // 曜日を取得。dayNo=０なら日曜日、dayNo=６なら土曜日となってます。

      dateArray.push(                                                  // push()で当月の日付<button>を配列の後続に入れていきます
        <DateBtn  
          key          = { madeDate }
          selected     = { madeDate　=== value ? true : false }
          focused      = { new Date( madeDate ) == cursorDate ? true : false }
          today        = { madeDate === this.today ? true : false }
          dayNo        = { dayNo }
          onClick      = { ()=>this.onClickDate( madeDate )}
          label        = { i }
        />
      )

      if(dayNo === breakPoint){ dateArray.push(<br key={ "now"+i } />) }                 // 最後にbreakPoint変数で選択した曜日の後に改行を入れる
        
    }
    // ________________________________________________________________________________________________________________

    //  当月のカレンダーの末尾に来る、来月の頭の日付けを作成    

    let endDayCount = new Date(year , month, 0).getDay()          // 月末の曜日Noを取得して、カウント変数にセット

    for(let i = 1; i <= 6; i++){

      if( endDayCount === breakPoint ) { break }                      // もしカウントがカレンダーの最後の曜日に達したら日付作成完了です  
      else if( endDayCount === 6 )     { endDayCount = 0 }            // また、もしカウントが6の場合、次は0になります。
      else                             { endDayCount ++  }            // 最後の曜日に達するまでカウントしていきます

      let YYYY = null
      let MM   = null
      if( month === 12 ){  // 1月の場合 
        YYYY  = year + 1
        MM    = '01'        
      }else{
        YYYY  = year
        MM  = ("0" + ( month + 1 ) ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      }
      const D = new Date(year , month, i).getDate()                 // forループに基づいて作成される次月の日付け
      const DD  = ("0" + D ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。

      const madeDate  = YYYY + "/" + MM + "/" + DD

      dateArray.push(
        <DateBtn  
          notThisMonth
          key          = { madeDate }
          selected     = { madeDate === value ? true : false }
          focused      = { new Date( madeDate ) == cursorDate ? true : false }
          today        = { madeDate === this.today ? true : false }
          onClick      = { ()=>this.onClickDate( madeDate )}
          label        = { D }
        />
      )
    }

    this.changeView = false; 
    // ________________________________________________________________________________________________________________
    
    return(
      <Fragment>
        <div style={ wrap }>
          <Labeling label={ label } must = { must } />
          <div style={text_wrap}>
          
          <input 
            type         = "tel"
            autoComplete = "off"
            style        = { Object.assign({}, text, style) }
            disabled     = { this.props.disabled    }
            ref          = { this.inputRef          }
            value        = { this.state.value||''   }
            onFocus      = { this.openCalendar      } 
            onMouseDown  = { this.handleOnMouseDown }
            onKeyDown    = { this.moveDate          }
            onChange     = { this.handleOnChage     }
            onBlur       = { this.handleOnBlur      } 
            onClick      = { ()  => this.setState({ showFlag  : true })}
            onMouseOver  = { ()  => this.setState({ hover : true  }) }
            onMouseLeave = { ()  => this.setState({ hover : false }) }
          />

          {this.state.showFlag &&
          <div style={calendar_wrap}  onMouseDown = {this.handleOnMouseDown}>
            { this.state.view !== "YYYY/MM" &&
            <Fragment>
              <div style={calendar_header}>
                <button style={changeMonth} onClick={(e)=>{this.moveMonth("pre",e)}}>  ≪ </button>
                <button style={calendar_title} onClick={ this.titleClick　}>
                  {title}    {/* 年月タイトル */}
                </button>
                <button style={changeMonth} onClick={(e)=>{this.moveMonth("next",e)}}> ≫ </button>
              </div>
              <div style={week_low}>
                {dayArray}　      {/* 曜日 */}
              </div>
              <div style={date_wrap}>
                {dateArray}       {/* 日付け */}
              </div>
            </Fragment>
            }
            { this.state.view === "YYYY/MM" &&
            <MonthView 
              year          = { this.state.year }
              monthsLang    = { monthsLang }
              preYear       = { ( )=> this.setState({ year: this.state.year - 1 }) }
              nextYear      = { ( )=> this.setState({ year: this.state.year + 1 }) }
              onClick       = { (i)=>this.monthOnClick(i) }
              selectedDate  = { this.state.selectedDate }
              value         = { value }
              todayMonth    = { this.todayMonth }
            />
            }
          </div>
          }

        </div>
        </div>
      </Fragment>
    )
    // ________________________________________________________________________________________________________________

  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class MonthView extends React.Component{

  render(){

    const {
      monthsLang, //  マルチ言語用の月名
      year,       // 年のstate 
      preYear,    // onClickイベント（ << ）
      nextYear,   // onClickイベント（ >> ）
      onClick,    // 月ボタン
      value,      // 選択されている値 YYYY/MM/DD 形式
      todayMonth, // 今月 YYYY/MM 形式
    }=this.props

    let   monthArr   = []; //表示する月を入れる配列
    const valueM     = new Date( value ).getMonth(); // 選択されている値から月を取得
    const valueMM    = ("0" + ( valueM + 1 ) ).slice(-2);  //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
    const valueYYYY  = new Date( value ).getFullYear();// 選択されている値から年を取得
    const valueMonth = valueYYYY + "/" + valueMM

    // ________________________________________________________________________________________________________________

    const calendar_header = {                  // <div>：カレンダーのヘッダー
      display:"flex",
      justifyContent:"space-between", 
      alignItems:"center",
      borderRadius:"inherit",
    }

    const changeMonth = {                       // <button>：月を切り替えるボタン
      border:"none",
      backgroundColor:"transparent",
      padding:8,
      fontSize:"20px",
      cursor:"pointer",
    }

    const calendar_title = {                // <button>：カレンダーのタイトル（年月）
      padding:8,
      backgroundColor: "transparent",
      border :"none",
      fontSize:"16px",
      whiteSpace: "nowrap",
      cursor:"pointer",
    }
    
    const month_wrap = {
      height:"100%",
      width:"180px"
    }
    
    // ________________________________________________________________________________________________________________
     
    for( let i in monthsLang ){
      const YYYY = year
      const MM  = ("0" + ( Number(i) + 1 ) ).slice(-2);  //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      const madeMonth  = YYYY + "/" + MM

      monthArr.push(
        <MonthBtn  
          key          = { i + "月" }
          selected     = { madeMonth === valueMonth }　// 作られる月 と 選択されている月 の正誤性 
          // focused      = { new Date( madeDate ) == cursorDate ? true : false }
          today        = { madeMonth === todayMonth }   // 作られる月 と 今月の正誤性
          onClick      = { ()=>  onClick && onClick( Number(i) + 1) }　// 親に表示されている月の値を送っている
          label        = { monthsLang[i] }  // 日本語なら1月,2月,3月,,,11月,12月。英語ならJan,Feb,Mar,,,,,Nov,Dec
        />
      )

      if( ( i + 1 ) % 3 === 0){　// 月ボタンを折り返す位置（3月,6月,9月）
        monthArr.push( <br key={ i+ "月br"} /> )
      }
    }

    return(
      <Fragment>
        <div style={calendar_header}>
          <button style={changeMonth} onClick={ preYear }>  ≪ </button>
          <button style={calendar_title}>
            { year }
          </button>
          <button style={changeMonth} onClick={ nextYear }> ≫ </button>
        </div>
        <div style={ month_wrap }>
          { monthArr }
        </div>
      </Fragment>
    )
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class DateBtn extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const { 
      selected,
      value,
      onClick,
      onKeyDown,
      label,
      style,
      focused,
      today,
      dayNo,
      notThisMonth,
      dayOfweek,
    } = this.props

    const button_style = {              // <button> リストアイテム
      minWidth:"14.285%",
      fontSize:12,
      outline: "none",
      height:25,
      border:"none",
      cursor:"pointer",
      borderRadius:5,
      marginBottom:"2px",
      boxSizing:"border-box",
      transition:"0.2s",
      color:
        focused       ? null   : //フォーカス中の日付け
        hover         ? null   : //オンマウス中の日付け
        selected      ? "#fff" : //選択中の日付け
        today         ? "#fff" : //今日の日付け
        notThisMonth  ? "#aaa" : //今月じゃない日付け
        dayNo === 6   ? "#00f" : //土曜日
        dayNo === 0   ? "#f00" : //日曜日
        null,
      backgroundColor:
        focused  ? "#ccc"    : //フォーカス中の日付け
        hover    ? "#ccc"    : //オンマウス中の日付け
        selected ? "#2b406c" : //選択中の日付け
        today    ? "#bbf"    : //今日の日付け
        "transparent",
      fontWeight:
        dayOfweek ? "bold" :
        null,
    }

    return(
      <button 
        style        = { Object.assign({}, button_style, style ) } 
        value        = { value }
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { label }
      </button>
    )
  }  
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class MonthBtn extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { hover } = this.state 
    const { 
      selected,
      value,
      onClick,
      onKeyDown,
      label,
      style,
      focused,
      today,
      dayOfweek,
    } = this.props

    const button_style = {              // <button> リストアイテム
      minWidth:"33.333%",
      height:30,
      fontSize:12,
      border:"none",
      cursor:"pointer",
      borderRadius: 5,
      outline: "none",
      marginBottom:"2px",
      boxSizing:"border-box",
      transition:"0.2s",
      color:
        focused       ? null   : //フォーカス中の月
        hover         ? null   : //オンマウス中の月
        selected      ? "#fff" : //選択中の月
        today         ? "#fff" : //今月
        null,
      backgroundColor:
        focused  ? "#ccc"    : //フォーカス中の月
        hover    ? "#ccc"    : //オンマウス中の月
        selected ? "#2b406c" : //選択中の月
        today    ? "#bbf"    : //今月
        "transparent",
      fontWeight:
        dayOfweek ? "bold" :
        null,
    }

    return(
      <button 
        style        = { Object.assign({}, button_style, style ) } 
        value        = { value }
        onClick      = { onClick }
        onKeyDown    = { onKeyDown }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { label }
      </button>
    )
  }  
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Labeling extends React.Component{
  render(){
    const { label, must }= this.props

    if( !label ){ return null }

    const label_style = {   // <label> テキストのラベル
      fontSize:14,
      marginRight:"1px",
      paddingLeft:"1px",
    }
    const must_style = {    // <span> 必須表示のアスタリスク（＊）
      fontSize:14,
      fontWeight:"bolder",
      color:"#d00", 
    }
    return(
      <div style={{ paddingBottom:"4px"}}>
        <label style={label_style}>{ label }</label>
        { must && <span style={ must_style } >＊</span> }
      </div>
    )
  }  
}
