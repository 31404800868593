import React, { Fragment,Component } from 'react';
import {Route,Switch,Prompt} from 'react-router-dom'
import { connect } from 'react-redux';
import { replace } from 'connected-react-router'　//Logout用
import PR001 from '../pr/PR001';
import PR002 from '../pr/PR002';
import PR003 from '../pr/PR003';

    //Menu機能をImportするFunction（非同期）
    const lazyLoader = (importComponent) => (
        class AsyncComponent extends Component {
          state = { C: null }
          async componentDidMount () {
            const { default: C } = await importComponent();
            this.setState({ C });
           }
           //アラート退避
           componentWillUnmount = () => {
             this.setState = (state,callback)=>{
               return;
             };
         }
           render () {
           const { C } = this.state;
           return C ? <C {...this.props} /> : null;
         }
       }
     );
     
     
class RouterContainer extends Component {
  constructor (props) {
    super(props);
    this.props.dispatch(replace('/'))
  }
  
  render(){
      return (
        <Fragment>
          {/* <Prompt 
            when={ this.props.location.pathname !== '/'}
              message={ location => {
                if(location.state && location.state.showPrompt === false){
                  return true;
                } else{
                  return "現在の画面から離れてもよろしいですか?"
                }
              }} 
              /> */}
          <Switch>
              <Route exact path="/" component={lazyLoader(() => import('./Home'))} />
              {/* 精度管理 */}
              <Route path='/qc001/QC001'  component={lazyLoader(() => import('../qc/qc001/index'))} />{/* データ入力 */}
              <Route path='/qc002/QC002'  component={lazyLoader(() => import('../qc/qc002/index'))} />{/* データ入力（集計済み） */}
              <Route path='/qc003/QC003'  component={lazyLoader(() => import('../qc/qc003/index'))} />{/* 分析装置データ取込 */}
              {/* <Route path='/qc004/QC004'  component={lazyLoader(() => import('../qc/qc004/index'))} /> */}
              {/* <Route path='/qc005/QC005'  component={lazyLoader(() => import('../qc/qc005/index'))} /> */}
              {/** edited */}
              <Route path='/qa001/QA001'  component={lazyLoader(() => import('../qa/qa001/index'))} />{/* edited: 外部精度管理 */}
              <Route path='/qa002/QA002'  component={lazyLoader(() => import('../qa/qa002/index'))} />{/* edited: 内部精度管理(管理図) */}
              <Route path='/qa003/QA003'  component={lazyLoader(() => import('../qa/qa003/index'))} />{/* edited: 内部精度管理(集計一覧表) */}
              <Route path='/qa004/QA004'  component={lazyLoader(() => import('../qa/qa004/index'))} />{/* edited: 内部精度管理(精度管理データ一覧表) */}
              <Route path='/qa005/QA005'  component={lazyLoader(() => import('../qa/qa005/index'))} />{/* edited: 内部精度管理(除外基準一覧表) */}
              {/* 情報提供 */}
              {/* <Route path='/ip001/IP001'  component={lazyLoader(() => import('../ip/ip001/index'))} /> */}
              {/* サポート */}
              <Route path='/sa001/SA001'  component={lazyLoader(() => import('../sa/sa001/index'))} />{/* ユーザーサポート */}
              <Route path='/sa002/SA002'  component={lazyLoader(() => import('../sa/sa002/index'))} />{/* 施設情報参照 */}         
  
              {/* 設定保守 */}
              <Route path='/mu001/MU001'  component={lazyLoader(() => import('../mu/mu001/index'))} />{/* 利用装置マスタ */}
              <Route path='/mu002/MU002'  component={lazyLoader(() => import('../mu/mu002/index'))} />{/* 利用コントロールマスタ */}
              <Route path='/mu003/MU003'  component={lazyLoader(() => import('../mu/mu003/index'))} />{/* 利用コントロールロットマスタ */}
              <Route path='/mu004/MU004'  component={lazyLoader(() => import('../mu/mu004/index'))} />{/* 利用コントロールロットマスタ（号機指定） */}
              <Route path='/mu005/MU005'  component={lazyLoader(() => import('../mu/mu005/index'))} />{/* 利用試薬ロットマスタ */}
              {/* <Route path='/mu006/MU006'  component={lazyLoader(() => import('../mu/mu006/index'))} /> */}
              <Route path='/mu007/MU007'  component={lazyLoader(() => import('../mu/mu007/index'))} />{/* コントロール内部精度管理情報設定マスタ */}
              <Route path='/mu008/MU008'  component={lazyLoader(() => import('../mu/mu008/index'))} />{/* 取込設定マスタ */}
              <Route path='/mu009/MU009'  component={lazyLoader(() => import('../mu/mu009/index'))} />{/* パスワード変更／登録 */}

              {/* 管理者用 */}
              <Route path='/ac001/AC001'  component={lazyLoader(() => import('../ac/ac001/index'))} />{/* 施設情報マスタ */}
              <Route path='/ac002/AC002'  component={lazyLoader(() => import('../ac/ac002/index'))} />{/* 参照施設設定 */}
              <Route path='/ac003/AC003'  component={lazyLoader(() => import('../ac/ac003/index'))} />{/* 施設アカウントマスタ */}
              <Route path='/ma001/MA001'  component={lazyLoader(() => import('../ma/ma001/index'))} />{/* 機能別権限チェックマスタ */}
              <Route path='/ma002/MA002'  component={lazyLoader(() => import('../ma/ma002/index'))} />{/* 集計グループマスタ */}
              <Route path='/ma003/MA003'  component={lazyLoader(() => import('../ma/ma003/index'))} />
              <Route path='/ma004/MA004'  component={lazyLoader(() => import('../ma/ma004/index'))} />{/* 装置マスタ */}
              <Route path='/ma005/MA005'  component={lazyLoader(() => import('../ma/ma005/index'))} />{/* コントロール試料マスタ */}
              <Route path='/ma006/MA006'  component={lazyLoader(() => import('../ma/ma006/index'))} />{/* コントロール項目マスタ */}
              <Route path='/ma007/MA007'  component={lazyLoader(() => import('../ma/ma007/index'))} />{/* コントロール項目初期値マスタ */}
              <Route path='/ma008/MA008'  component={lazyLoader(() => import('../ma/ma008/index'))} />{/* コントロールロット目標値マスタ */}
              <Route path='/ma009/MA009'  component={lazyLoader(() => import('../ma/ma009/index'))} />{/* サポ－トデスク監視担当マスタ */}
              <Route path='/ma010/MA010'  component={lazyLoader(() => import('../ma/ma010/index'))} />{/* 管理地域マスタ */}
              <Route path='/ma011/MA011'  component={lazyLoader(() => import('../ma/ma011/index'))} />{/* 文書種マスタ */}
              <Route path='/ma012/MA012'  component={lazyLoader(() => import('../ma/ma012/index'))} />{/* 取込設定パラメータマスタ */}
              <Route path='/ma014/MA014'  component={lazyLoader(() => import('../ma/ma014/index'))} />
              <Route path='/ma015/MA015'  component={lazyLoader(() => import('../ma/ma015/index'))} />
              {/* <Route path='/ma016/MA016'  component={lazyLoader(() => import('../ma/ma016/index'))} /> */}
              <Route path='/ma017/MA017'  component={lazyLoader(() => import('../ma/ma017/index'))} />{/* 測定方法マスタ */}
              <Route path='/ma019/MA019'  component={lazyLoader(() => import('../ma/ma019/index'))} />{/* 試薬メーカマスタ */}
              <Route path='/ma020/MA020'  component={lazyLoader(() => import('../ma/ma020/index'))} />{/* 操作ログ */}
              {/* ツール */}
              <Route path='/ma013/MA013'  component={lazyLoader(() => import('../ma/ma013/index'))} />{/* コントロールロットの変更を核施設に反映させる */}
              <Route path='/ma018/MA018'  component={lazyLoader(() => import('../ma/ma018/index'))} />{/* ロット利用施設一括登録 */}
              <Route path='/ma021/MA021'  component={lazyLoader(() => import('../ma/ma021/index'))} />{/* 報告データのロット名称を変更する */}
              <Route path='/ma022/MA022'  component={lazyLoader(() => import('../ma/ma022/index'))} />{/* 施設のマスタ一覧をCSV出力する */}
              <Route path='/ma023/MA023'  component={lazyLoader(() => import('../ma/ma023/index'))} />{/* 施設アカウントの使用状況を一括で変更する */}
              <Route path='/ma024/MA024'  component={lazyLoader(() => import('../ma/ma024/index'))} />{/* 試薬ロット目標値マスタ */}
              {/* 説明リンク */}
              <Route path='/pr/PR001'  component={PR001} />{/* コントロールロットの変更を核施設に反映させる */}
              <Route path='/pr/PR002'  component={PR002} />{/* コントロールロットの変更を核施設に反映させる */}
              <Route path='/pr/PR003'  component={PR003} />{/* コントロールロットの変更を核施設に反映させる */}
        </Switch>
        </Fragment>

      )
  }
}
const mapStateToProps = (state,ownPropS) =>{
    return {
      //stateの範囲を縮めるとRender回数は減ります
      ...state.router,
    }
  }
  export default connect(mapStateToProps)(RouterContainer);