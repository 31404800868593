 import request from 'superagent';
 import axios from 'axios';
 import {axiosPost,server} from '../comRedux/axiosPost';
 
 /*
   1 Level JSON作成
   returnJson : {
        NAME :VALUE
   }
 */
function toJson(props) {
  let returnJson = {};
  for(let v in props){
    //項目がNullじゃない　且つ　ValueはUndefinedじゃない時、継続
    if(props[v] && typeof(props[v]['value']) !== 'undefined'){
      returnJson[v] = props[v].value;
    }
  }
  return returnJson;
}

function toJsonState(props) {
  let returnJson = {};
  for(let v in props){
    returnJson[v] = props[v].value;
  }
  return returnJson;
}

function toJsonList(forms,detailList) {
  let formData = forms.elements;
  let returnJson = {};
  let formDataStr;
  for (var idx = 0; idx < formData.length; idx++) {
    if (formData[idx].name!='') {
      returnJson[formData[idx].name] = formData[idx].value;
      formDataStr += formData[idx].name + " : " + formData[idx].value + "\n";
    }
  }
  returnJson['detailFormBean'] = detailList;
  //alert(formDataStr);
  return returnJson;
}

function checkDate(y, m, d){
  let dt = new Date();
  dt.setYear(y);
  dt.setMonth(m - 1);
  dt.setDate(d);
  return (y == dt.getFullYear() && (m - 1) == dt.getMonth() && parseInt(d) == dt.getDate()); 
};
    
function matchesSelector(element, selector) {
  if(element.matches) {
    return element.matches(selector);
  } else if(element.matchesSelector) {
    return element.matchesSelector(selector);
  } else if(element.webkitMatchesSelector) {
    return element.webkitMatchesSelector(selector);
  } else if(element.msMatchesSelector) {
    return element.msMatchesSelector(selector);
  } else if(element.mozMatchesSelector) {
    return element.mozMatchesSelector(selector);
  } else if(element.oMatchesSelector) {
    return element.oMatchesSelector(selector);
  }
}
 
 function number_format(number, decimals, dec_point, thousands_sep){
  /*
　* number：要格式化的数字
　* decimals：保留几位小数
　* dec_point：小数点符号
　* thousands_sep：千分位符号
　*/
  // number = (number + '').replace(/[^0-9+-Ee.]/g, '');
  let n = !isFinite(+number) ? 0 : +number,
  prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
  sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
  dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
  s = '',

  toFixedFix = function(n, prec){
    let k = Math.pow(10, prec);
    return '' + Math.round(n * k) / k;
  };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  
  if(!(typeof thousands_sep === 'undefined')){
    let re = /(-?\d+)(\d{3})/;
    while(re.test(s[0])) {
      s[0] = s[0].replace(re, "$1" + sep + "$2");
    }
  }

  if((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};

function  ToHalf(str) { 
  var tmp = ""; 
  for(var i=0;i<str.length;i++){ 
    if (str.charCodeAt(i) === 12288){
      tmp += String.fromCharCode(str.charCodeAt(i)-12256);
      continue;
    }
    if(str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375){ 
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248); 
    } 
    else{ 
      tmp += String.fromCharCode(str.charCodeAt(i)); 
    } 
  } 
  return tmp;
};

function ToFull(str){ 
  let tmp = "";
  for(let i = 0; i < str.length; i++ ){
    if(!(str.charAt(i).match(/[\uff00-\uffff]/g) == null)){
      tmp = tmp + str.charAt(i);
      continue;
    }
    if(str.charCodeAt(i) === 32){
      tmp = tmp　+ String.fromCharCode(12288);
      continue;
    } 
    if(str.charCodeAt(i) < 127){ 
      tmp = tmp + String.fromCharCode(str.charCodeAt(i) + 65248); 
    } 
  }
  return tmp; 
};
// MUSTがあるコンポーネントの必須チェック(検索画面)
function checkProps(props) {
  for(let v in props) {
    if(!props[v]) continue;//項目がNull時Continue
    const { value, must, noCheck } = props[v];      // 省略宣言
    // console.log(v,"_",value)

    // must が設定されていなければ ループをスキップ
    if(!must || must.length <= 0) { continue; } 
    
    //  エラーチェック　type : { value :[], must: true } 型に対応するため value.length <= 0 で対応しています
    if(!value || value.length <= 0) { 
      // console.log("no error");
      return false; 
    }   
    
    //  type : { value :[], must: [] } 型のエラーチェック
    if(Array.isArray(value) && Array.isArray(must)) { 　// valueがArray型なら 
      for( let i in value ){        // 行ループ;
        let inValue = false;     // 値が入っているかチェックするための変数を宣言
        for(let j in value[i]) { // 列ループ
          if(value[i][j]) {  // 値が入っていたら　
            if(noCheck && noCheck.length > 0 && noCheck.indexOf(j) !== -1){

            }else {
              inValue = true;   　// 値が入っていることを認識
              break;
            }
          }
        }

        if(inValue || i === '0'){  // 行に値が入っていたら または ループが1行目をだったら
          for(let j in value[i]) {   // 列ループ
            let result = must.indexOf(j)      // 配列の中身を検索してmust指定したものを検索
            if(result !== -1 && !value[i][j]) {  // そのセルがmustでかつ値がなければ
              return false; // エラー
            }   
          }
        }
      }
    }
  }
  return true;
};

// MUSTがあるコンポーネントの必須チェック(登録画面)
// function checkInputProps(props) {
//     for(let v in props.inputValues){
//         if(props.inputValues[v].must == true &&
//             (props.inputValues[v].value == 'undefined' || props.inputValues[v].value == "")){
//                 return false;
//             }
//     }
//     return true;
// };
function loadSearchJson(state,json){
  //jsonを遍歴
  for(let v in json){
    //InputValuesに存在する項目のみセットする
    if(typeof(state.searchValues[v]) !== 'undefined'){
      //項目の値はValueに管理する場合
      if(state.searchValues[v].hasOwnProperty('value')){
        // console.log(state.inputValues[v],json[v])
        state.searchValues[v].value = json[v];
        // console.log(state.inputValues[v])
      }else{
        //項目の直下に値を管理する場合
        state.searchValues[v] = json[v];
      }
    }
  }
  // console.log(state.inputValues)
}
function loadInputJson(state,json,clearArr){
  //jsonを遍歴
  // console.log(json)
  for(let v in json){
    //InputValuesに存在する項目のみセットする
    if(typeof(state.inputValues[v]) !== 'undefined'){
      //特別の項目は特別で対応
      if(Array.isArray(clearArr) && clearArr.indexOf(v) > -1){
        state.inputValues[v].value = '';// クリア
        continue;
      }
      //項目の値はValueに管理する場合
      if(state.inputValues[v].hasOwnProperty('value')){
        // console.log(state.inputValues[v],json[v])
        state.inputValues[v].value = json[v];
        // console.log(state.inputValues[v])
      }else{
        //項目の直下に値を管理する場合
        state.inputValues[v] = json[v];
      }
    }
  }
  // console.log(state.inputValues)
}

function mapDataKeyFromJson(state,json,dataKeys) {
  if(isEmptyObject(json)) { return null; }
  for(let i in dataKeys) {
    let name = dataKeys[i];
    //項目の値はValueに管理する場合
    if(typeof(state.inputValues[name]) === 'undefined') {
      state.inputValues[name] = '';
    }else {
      if(state.inputValues[name].hasOwnProperty('value')) {
        state.inputValues[name].value = json[name];
      }else {
        //項目の直下に値を管理する場合
        state.inputValues[name] = json[name];
      }
    }
  }
}

function mapDataKeyToPayload(payload,props,dataKeys){
  if(isEmptyObject(props)) { return null; } //ObjectはNullかどうか
  for(let i in dataKeys) { 
    let name = dataKeys[i]; 
    if(typeof(props[name]) === 'undefined') {
      payload[name] ='';
    }else {
      // console.log("keyの",name,"の値は",props[name])
      if(props[name].hasOwnProperty('value')) {
        //項目は定義していない　もしくは　項目の直下に値を管理する場合
        payload[name] = props[name].value;
      }else {
        //項目の値はValueに管理する場合
        payload[name] = props[name];
      }
    }
  }
}

//ObjectはNullかどうか
function isEmptyObject(obj){
  for(let key in obj){
    return false
  };  
  return true  
}
// ObjectのValueと項目は一致していますか
 function isObjectValueEqual(a, b) {
    if (a === b) return true
    let aProps = Object.getOwnPropertyNames(a)
    let bProps = Object.getOwnPropertyNames(b)
    if (aProps.length !== bProps.length) return false
    for (let prop in a) {
     if (b.hasOwnProperty(prop)) {
      if (typeof a[prop] === 'object') {
       if (!isObjectValueEqual(a[prop], b[prop])) return false
      } else if (a[prop] !== b[prop]) {
       return false
      }
     } else {
      return false
     }
    }
    return true
   }

async function getOptions(url,...names){
  let options = [{ 'value': '', 'label': '' }];
  return new Promise((resolve, reject) => {
    axiosPost.get(server + url)
    .then((res) => {
      let result = null;
      if(!!res.data){
        result = res.data;
        if(names && names.length > 0){
          if(names.length === 1){
            result = res.data[names[0]];
          }else{
            for(let i = 0;i< names.length;i++){
              result[i] = res.data[names[i]];
            }
          }
        }
      }
        resolve(result);
    })
    .catch((err) => { reject(err) });
  })
}

async function getOptionsByPost(url,data,...names){
  let options = [{ 'value': '', 'label': '' }];
  return new Promise((resolve, reject) => {
    axiosPost.post(server + url,data)
    .then((res) => {
      let result = null;
      if(!!res.data){
        result = res.data;
        if(names && names.length > 0){
          if(names.length === 1){
            result = res.data[names[0]];
          }else{
            for(let i = 0;i< names.length;i++){
              result[i] = res.data[names[i]];
            }
          }
        }
      }
        resolve(result);
    })
    .catch((err) => { reject(err) });
  })
}

async function getMultiRequest(requestList){
  if(requestList.length > 0){
    return (  
      axios.all(requestList)
      .then(
        axios.spread(function(res1,res2){
          return [res1.data, res2.data];
        })
      )
      .catch((err) => console.log(err))
    )
  }
};

async function getJSON(url){
  let p = new Promise(
    (resolve, reject) =>{
      request.get(url).accept('application/json')
      .end((err, res) => {
        if(err) {
          console.log('JSON読み込みエラー')
          reject(err);
        }
        resolve(res.body);
      })
    }
  );

  let result = p.then((res) => res );
  return result;
};

async function requiredCheck(state){
  let checkFn = new Promise(
    (resolve) =>{}
  );
}

async function getName(url,key,name){
  var res='';
  await axiosPost.get(server+url+'/?key='+key).then(rtn => {res = rtn.data[name]; });
  return res;
}

function isValid(prop){
    if(prop.must){
        if(prop.value == ''|| prop.value == 0 || typeof(prop.value)=='undefined'){
            return false;
        }else{
            return true;
        }
    }
    return true;
}

function isValidS(prop, must) {
  if(must){
    if(prop.value === '' || typeof(prop.value) === 'undefined'){
      return false;
    }else{
      return true;
    }
  }
  return true;
}
function safeEval(val) {
  if (val) {
    return Function('"use strict";return (' + val + ')')();
  } else {
    return;
  }
}
//__________________________________________________________________

// 日付を計算する処理
// calcDate({date:"2021/08/23", d: -1 }) →  "2021/08/22"
// calcDate({type:"YYYY/MM" date:"2021/08/31", d: 1 }) →  "2021/09"
// calcDate({type:"YYYY/MM/DD" date:"2021/08/23",m: 1, d: 1 }) →  "2021/09/23"
function calcDate(obj) {
  const { type, date, y, m, d } = obj // 引数はObjectで受け取る
  let newDate = date ? new Date(date) : new Date();
  let _year = newDate.getFullYear();
  let _month = newDate.getMonth();
  let _date = newDate.getDate();
  _year = y ? safeEval(_year + y) : _year;
  _month = m ? safeEval(_month + m) : _month;
  _date = d ? safeEval(_date + d) : _date;
  const calcNewDate = new Date(_year, _month, _date);
  return convertDate({ type: type, date: calcNewDate });
}
//__________________________________________________________________

// 日付を YYYY/MM/DD 形式に変更するファンクション（作：Kondo）
function changeToYMD(newDate) {
  const argDate = new Date(newDate);
  const year  = argDate.getFullYear() ;                       
  const month = argDate.getMonth()+1;
  const date  = argDate.getDate();
  const MM = ("0" + month ).slice(-2); // sliceメソッド で頭に '0' を追加
  const DD = ("0" + date  ).slice(-2); // sliceメソッド で頭に '0' を追加
  return (year + "/" + MM + "/" + DD); // YYYY/MM/DD で返す
}
//__________________________________________________________________

// 日付の表記を整理する処理
// convertDate({type:'YYYY/MM/DD', y:2020, m:11, d:10}) →　return '2020/11/10'
// convertDate({type:'YYYY/MM', y:2020, m:11, d:10}) →　return '2020/11'
// convertDate({type:'YYYY/MM/DD', date: new Date()}) →　return '2020/11/10'
function convertDate(obj) {
  const { type = '', date, y, m, d } = obj // 引数はObjectで受け取る
  const newDate = date ? date : new Date(y, m, d);
  const newYear = newDate.getFullYear();
  const newMonth = newDate.getMonth() + 1;
  const localDate = newDate.getDate();
  const MM = ("0" + newMonth).slice(-2); // sliceメソッド で頭に '0' を追加
  const DD = ("0" + localDate).slice(-2); // sliceメソッド で頭に '0' を追加
  if (newDate === 'Invalid Date') { return '' }
  // typeによって変換する形式を変更する
  switch (type.toUpperCase()) {
    case 'YYYY/MM/DD': {
      return (newYear + "/" + MM + "/" + DD); // YYYY/MM/DD で返す
    }
    case 'YYYY/MM': {
      return (newYear + "/" + MM); // YYYY/MM で返す
    }
    case 'MM/DD': {
      return MM + '/' + DD;
    }
    case 'YYYYMMDD': {
      return newYear + "" + MM + "" + DD
    }
    default: {
      return (newYear + "/" + MM + "/" + DD); // YYYY/MM/DD で返す
    }
  }
}
//__________________________________________________________________

//stateの内部で、orgReducer領域のデータを戻す
//orgReducer：COMMON.COMM / COMM.Login
function selectImportantData(state,orgReducer){
  if(orgReducer === '' || typeof orgReducer === 'undefined')return {};
  const paths = orgReducer.split('.');
  let data = state;
  for(let i = 0;i<paths.length;i++){
      data = data[paths[i]];
  }
  return data
}

export{
  calcDate,
  checkDate,
  matchesSelector,
  number_format,
  ToHalf,
  checkProps,
  ToFull,
  loadSearchJson,
  loadInputJson,
  mapDataKeyFromJson,//Reducer用
  mapDataKeyToPayload,//Searchlist&Input用
  toJsonList,
  toJson,
  toJsonState,
  getOptions,
  getOptionsByPost,
  getJSON,
  isValid,
  isValidS,
  getMultiRequest,
  changeToYMD,
  getName,    
  selectImportantData,
  isObjectValueEqual,
};