import TextNum from './TextNumber';
import Textarea from './TextArea';
import Text from "./Text";
import TextLabel from "./TextLabel";
import TextModal from "./TextModal";
import Select from './Select';
import Btn from './Button';
import Link from './Link';
import Icon from './Icon';
import RadioList from './RadioList';
import CheckBox from './CheckBox';
import Calendar from './Calendar';
import Form from './Form'
import {confirm} from './Confirm';
import { openModal, closeModal } from './Modal'
import Hideable from './Hideable';
import ListBox  from  './ListBox';
import Tilde  from "./Tilde";

import Loading from './Loading';
import Blank from './Blank';
import InputTable from './InputTable';// 入力テーブル
import ListTable from './ListTable';// 一覧テーブル


// import TestTable from './InputTable_test';// 入力テーブル

import Centering from "./Centering"
import Low from './Low';
import Col from './Col';

export {
  Text
  ,TextNum
  ,TextModal
  ,Textarea
  ,TextLabel
  ,Calendar
  ,Hideable
  ,Form
  ,confirm
  ,openModal
  ,closeModal
  ,Tilde

  ,Select
  ,CheckBox
  ,RadioList
  ,ListBox

  ,Btn
  ,Link
  ,Icon

  ,Centering
  ,Loading
  ,Low
  ,Col
  ,Blank
  ,InputTable
  ,ListTable
  // ,TestTable

  

}

